<script>
import GlobalVue from '@helper/Global.vue';
import Gen from '@helper/Gen';
export default {
	extends: GlobalVue,
	data() {
		return {
			static:{}
		}
	},
	async mounted() {
		this.apiGetStatic()
	},
	computed:{
		yearcopy(){
			let year = 2020
			let cyear = (new Date).getFullYear()
			return year == cyear ? year : year+" - "+cyear
		}
	},
	methods: {
		async getStatic(id){
            return (await Gen.apirest('/web-settings', {id:id}, ()=>{})).data
        },
        async apiGetStatic(){
	        this.static = await this.getStatic(1)
	    },
	}

};
</script>
<template>
	<footer id="footer">
		<div id="copyrights">
			<div class="container clearfix">
				<div class="col_full center mb-0">
					<div>
						Copyright &copy; {{yearcopy+' '+static.aws_val}}. <br> All Rights Reserved.<br>
						<div class="copyright-links"><router-link :to="{name:'TermOfUse'}">{{web.mn_term}}</router-link> / <router-link :to="{name:'PrivacyPolicy'}">{{web.mn_privacy}}</router-link></div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>